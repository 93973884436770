<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- mega menu -->
    <!-- ============================================================== -->
    <!-- End mega menu -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
        </div>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">4 New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center m-b-5" href="javascript:void(0);">
                        <strong>Check all notifications</strong>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li>
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <span class="text-white">{{greetings}} </span><span class="ml-1 text-white">{{firstName}}</span>
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/1.jpg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="custom-bg"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 custom-bg text-white m-b-10">
                <div class="">
                    <img [src]="'assets/images/'+firstName+'.jpg'" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <p class=" m-b-0">{{userName}}</p>
                </div>
            </div>
            <a class="dropdown-item" routerLink="/authentication/change-password" *ngIf="this.roleName!=='23'">
                <i class="fa fa-key m-r-5 m-l-5" ></i> Change Password
            </a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
            </a>
        </div>
    </li>
</ul>