import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { AccountServices } from 'src/app/core/services/account.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LeadGenerationService } from 'src/app/core/services/lead-generation.service';

declare var $: any;

@Component({
  selector: 'app-horizontal-navigation',
  templateUrl: './horizontal-navigation.component.html',
  styleUrls: ['./horizontal-navigation.component.scss']
})
export class HorizontalNavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  userInfo: any;
  userName: any;
  firstName: any;
  greetings: string = '';
  roleId: any;
  unreadCount: number = 0;
  public isCollapsed = false;
  public showMobileMenu = false;
  activityLogList: Array<any> = [];

  notifications: Array<{ btn: string, icon: string, title: string, subject: string, time: string, readStatus: any }> = [];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'French',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Spanish',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }]

  constructor(private modalService: NgbModal, private translate: TranslateService,
    private _account: AccountServices, private router: Router, private storageService: LocalStorageService,
    public leadGenerationService: LeadGenerationService,) {

    this.roleId = sessionStorage.getItem('roleId');
    this.userInfo = storageService.getUserInfo();
    this.userName = this.userInfo.name;
    translate.setDefaultLang('en');
    translate.setDefaultLang('en');
    this.leadGenerationService.activityLogList(0).subscribe(res => {
      this.activityLogList = res.data;
      if (this.activityLogList != null) {
        this.unreadCount = this.activityLogList[0].unreadCount;
        this.activityLogList.forEach(el => {
          this.notifications.push({
            btn: 'btn-danger',
            icon: 'fas fa-comment-dots',
            title: 'Sales Lead',
            subject: el.taskName,
            time: el.createdDate,
            readStatus: el.readStatus
          })
        })
      }
    })
  }

  ngAfterViewInit() { }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }
  logout() {
    this._account.logout();
    this.router.navigate(['/authentication/login']);
  }
  onClickNotification() {
    if (this.unreadCount !== 0) {
      this.leadGenerationService.updateReadStatus().subscribe(res => {
        console.log(res);
        setTimeout(() => {
          this.unreadCount = 0;
        }, 3000);
      })
    }
  }
}