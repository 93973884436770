export class UserDetails{
    constructor(
        public userName : string = '',
        public firstName : string = '',
        public lastName : string = '',
        public company : string = '',
        public role : string = '',
        public emailId : string = '',
        public tokenId : string = ''

        ){}
}


 
       
         