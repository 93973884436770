import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
 
 

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  expireOn: string='';
  userId =0;
  constructor(
    private router: Router,
    private service: LocalStorageService
  ) {
    this.service.currentUser.subscribe((res: any) => {
       this.expireOn= res?.expireOn;//new Date() > new Date(res.expireOn)? true: false;
       this.userId = res.id ? res.id : 0;
    })
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expired=new Date() > new Date(this.expireOn) ? true: false
    if(this.userId > 0 && !expired){
      return true;
    }
    this.router.navigate(['/authentication/login']);
    return false;
  }
}
