import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DashboardEmailModel, DashboardFilterModel } from 'src/app/model/project-model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private dashboardFilterObj= new DashboardFilterModel();
  private dashboardFilterSubject: BehaviorSubject<DashboardFilterModel> = new BehaviorSubject<DashboardFilterModel>(this.dashboardFilterObj);
  public dashboardFilterModel = this.dashboardFilterSubject.asObservable()

  constructor() { }

  setDashboardFilterModel(model: DashboardFilterModel) {
    this.dashboardFilterSubject.next(model);
  }
  
  resetDashboardFilterModel() {
    this.dashboardFilterSubject.next(new DashboardFilterModel());
  }
}
