<!-- Navbar Header for horizontal only -->
<div class="navbar-header d-none d-lg-flex justify-content-center">
    <!-- This is for the sidebar toggle which is visible on mobile only -->
    <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none"
        href="javascript:void(0)">
        <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
    </a>
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <a class="navbar-brand" routerLink="/dashboard/ad-injector">
        <!-- Logo icon -->
        <b class="logo-icon">
        </b>
        <span class="logo-text">
            <img src="assets/images/whonHow_BLACK200x200.png" width="auto" class="dark-logo" alt="homepage" />
        </span>
    </a>

    <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent">
        <i class="ti-more"></i>
    </a>
</div>

<div class="d-none d-sm-block d-sm-none d-md-block table-v">
    <app-horizontal-sidebar></app-horizontal-sidebar>
</div>
<ul class="navbar-nav float-md-right">
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right" *ngIf="roleId==='1' || roleId==='2'">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="onClickNotification()">
            <span [ngClass]="unreadCount!==0 ? 'bell-notification' : ''">
                <i class="mdi mdi-bell font-24"></i>
            </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">{{unreadCount + ' New'}}</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <div class="message-center notifications" [perfectScrollbar]="config">
                        <!-- Message -->
                        <a href="javascript:void(0)" class="{{notification.readStatus === 1? 'message-item' : 'message-item unread'}}"
                            *ngFor="let notification of notifications">
                            <span class="btn btn-circle {{notification.btn}}">
                                <i class="{{notification.icon}}"></i>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{notification.title}}</h5>
                                <span class="mail-desc"><span [innerHTML]="notification.subject"></span></span>
                                <span class="time text-info">{{notification.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>

            </ul>
        </div>
    </li>
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img [src]="'assets/images/'+ userName.split(' ')[0]+'.png'" alt="user" class="rounded-circle" width="31"
                onError="this.src='assets/images/1.jpg'">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="custom-bg"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 custom-bg m-b-10">
                <div class="">
                    <img [src]="'assets/images/'+ userName.split(' ')[0]+'.png'"
                        onError="this.src='assets/images/1.jpg'" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class=" m-b-0">{{userName}}</h4>
                </div>
            </div>
            <a class="dropdown-item" routerLink="/authentication/change-password" *ngIf="this.roleId!=='5'">
                <i class="fa fa-key m-r-5 m-l-5"></i> Change Password
            </a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
            </a>
        </div>
    </li>
</ul>