import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less']
})
export class LoadingComponent {
  @Input('busy')
  public busy: boolean = false;

  @Input('message')
  public message: string = 'Loading...';

}
