export class ProjectModel {
    constructor(
        public projectName: string = '',
        public device: number = 0,
        public browser: number = 0,
        public location: number = 0,
        public searchEngine: number = 0,
        public searchEngineDepth: number = 0,
        public ads: boolean = false,
        public pla: boolean = false,
        public searchKeywords: string = '',
        public matchKeywords: string = '',
        public trackFilter: Array<AdsCrawlerTrackingURLModel> = [],
        public brandUrls: string = '',
        public recurring: boolean = false,
        public startOn: Date = new Date(),
        public frequencyType: number = 0,
        public insertedBy: number=0
    ) { }
}


export class AdsCrawlerTrackingURLModel {
    constructor(
        public trackingUrl: string = '',
        public param1: string = '',
        public param2: string = '',
        public param3: string = '',
    ) { }
}

export class DashboardCountModel {
    constructor(
        public project: number = 0,
        public task: number = 0,
        public brandBidding: number = 0,
        public brandAds: number = 0,
        public nonBrandBidding: number = 0,
        public directLink: number = 0,
        public pubId: number = 0,
        public campId: number = 0,
        public customId: number = 0
    ) { }
}

export class DashboardFindingModel {
    constructor(
        public brandBidding: number = 0,
        public brandAds: number = 0,
        public nonBrandBidding: number = 0,
        public directLink: number = 0,
        public pubId: number = 0,
        public campId: number = 0,
        public customId: number = 0
    ) { }
}

export class FilterModel{
    constructor(
        public fromDate: string='',
        public toDate: string='',
    ) {}
}

export class DashboardFilterModel{
    constructor(
        public fromDate: string='',
        public toDate: string='',
        public projectId: number = 0,
        public findingType: string=''
    ) {}
}
export class DashboardEmailModel{
    constructor(
        public fromDate: string='',
        public toDate: string='',
        public projectId: number = 0,
        public findingType: string=''
    ) {}
}

export class ParameterReportModel{
    constructor(
    public pubId: string='',
    public campId: string='',
    public customId: string='',
    public brandBidding: string='',
    public nonBrandBidding: string='',
    public keywordCount: string='',
    public directLink: string=''
    ) {}
  }

  export class NotificationModel{
    constructor(
        public id : number = 0,
        public notifyName : string = '',
        public notifyEmails : string = '',
        public notifyCCEmails : string = '',
        public eventId : number = 0,
        public eventName : string = '',
        public notificationType : boolean = false,
        public reportType : boolean = false,
        public pubId : string = '',
        public campId : string = '',
        public customId: string='',
        public ir : boolean = false,
        public cs : boolean = false,
        public affiliation : boolean = false,
        public misleading : boolean = false,
        public image : boolean = false,
        public text : boolean = false,
        public status : boolean = false,
    ) {}
}


