import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlParams } from '../constants/URL_PARAMS';

@Injectable({
  providedIn: 'root'
})
export class ShareLinkLogService {

  constructor(private http:HttpClient) { }

  postShareLinkLog(shareModelLog:any): Observable<any> {
    const url = `${UrlParams.ACCOUNT.CREATE_SHARELINKLOG}`;
    return this.http.post<any>(url,shareModelLog);
  }
}
