import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServices } from 'src/app/core/services/account.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';


@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html'
})
export class VerticalSidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  userInfo:any;
  userName:any;
  firstName:any;
  greetings:string='';

  constructor(private menuServise: VerticalSidebarService, private router: Router,
    private _account: AccountServices, private storage: LocalStorageService) {
    this.userInfo = this.storage.getUserInfo();
    this.userName = this.userInfo.userName;
    this.firstName = this.userInfo.firstName;

    this.menuServise.items.subscribe((menuItems: Array<any>) => {   
      this.sidebarnavItems = menuItems.filter(x=>x.role.indexOf(this.userInfo?.roleId ) != -1);

      // Active menu 
      this.sidebarnavItems.filter(m => m.submenu.filter(
        (s) => {
          if (s.path === this.router.url) {
            this.path = m.title;
          }
        }
      ));
      this.addExpandClass(this.path);
    });
  }

  ngOnInit(){
    var myDate = new Date();
    var hrs = myDate.getHours();
    var greet='';

    if (hrs < 12)
        greet = '🌅 Good Morning !';
    else if (hrs >= 12 && hrs <= 17)
        greet = '🌞 Good Afternoon !';
    else if (hrs >= 17 && hrs <= 24)
        greet = '🌇 Good Evening !';

    this.greetings = greet;

  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  logout(){
    this._account.logout();
    this.router.navigate(['/authentication/login']);
  }

}
