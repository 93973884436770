<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/background/login-register.jpg) no-repeat center center;">
    <div class="auth-box m-3 m-md-0">
        <div id="loginform">
            <div class="logo">
                <span class="db"><img src="../../../../assets/images/theClickAD.png" alt="logo" /></span>
                <h5 class="font-medium m-b-20">Sign In to Admin</h5>
            </div>
            <!-- Form -->
            <div class="row">
                <div class="col-12">
                    <form class="form-horizontal m-t-20" id="loginform">
                        <div class="form-group row ">
                            <div class="col-12 ">
                                <input class="form-control" type="text" required=" " placeholder="Name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 ">
                                <input class="form-control" type="text" required=" " placeholder="Email">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 ">
                                <input class="form-control" type="password" required=" " placeholder="Password">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 ">
                                <input class="form-control" type="password" required=" " placeholder="Confirm Password">
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <a class="btn btn-block btn-lg btn-info" href="">Sign Up</a>
                            </div>
                        </div>
                        <div class="form-group m-b-0 m-t-10">
                            <div class="col-sm-12 text-center">
                                Already have an account? <a routerLink="/authentication/login" class="text-info m-l-5"><b>Sign In</b></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>