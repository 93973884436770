import { RouteInfo } from './vertical-sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: '',
        icon: '',
        class: '',
        extralink: true,
        label: '',
        labelClass: '',
        role: ['3', '2', '4', '1'],
        submenu: []
    },
    {
        path: '/dashboard/ad-injector',
        title: 'Dashboard',
        icon: 'Home',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['2', '1', '4', '3'],
        submenu: []
    },
    {
        path: '/managereports/manage-reports',
        title: 'Reports Summary',
        icon: 'Layers',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2', '4', '1'],
        submenu: []
    },
    {
        path: '/managereports/pending-reports',
        title: 'Pending Reports',
        icon: 'Clock',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2', '4', '1'],
        submenu: []
    },
    {
        path: '/managereports/approved-reports',
        title: 'Approved Reports',
        icon: 'Check',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2', '4', '1'],
        submenu: []
    },
    {
        path: '/managereports/duplicate-reports',
        title: 'Duplicate Reports',
        icon: 'Pause',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2', '4', '1'],
        submenu: []
    },
    {
        path: '/managereports/bulkfile-upload',
        title: 'Bulk Upload',
        icon: 'File',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2','1'],
        submenu: []
    },
    {
        path: '/admin/manage-company',
        title: 'Company',
        icon: 'Box',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['1'],
        submenu: []
    },
    {
        path: '/admin/manage-user',
        title: 'Manage User',
        icon: 'users',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['3', '2', '1'],
        submenu: []
    },
    {
        path: '/managereports/validation-list',
        title: 'Validation Checklist',
        icon: 'Shield',
        class: '',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['2', '1'],
        submenu: []
    },
    {
        path: '',
        title: 'Settings',
        icon: 'settings',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: 'badge badge-success sidebar-badge',
        role: ['1'],
        submenu: [
            {
                path: '/admin/manage-AffModel',
                title: 'Manage Aff Model',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-browser',
                title: 'Manage Browser',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-creativeType',
                title: 'Manage Creative Type',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-installation',
                title: 'Manage Installation',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-masterNetwork',
                title: 'Manage Network',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-PageType',
                title: 'Manage Page Type',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-platform',
                title: 'Manage Platform',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-proxy',
                title: 'Manage Proxy',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-searchengine',
                title: 'Manage Search Engine',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/manage-brand',
                title: 'Manage Brand',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
            {
                path: '/admin/package-list',
                title: 'Manage Package',
                icon: 'settings',
                class: '',
                extralink: false,
                label: '',
                labelClass: 'badge badge-success sidebar-badge',
                role: ['1'],
                submenu: []
            },
        ]
    },
];
