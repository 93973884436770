<div class="loading-container" [class.busy]="busy">
  <div class="loading-backdrop"></div>
  <div [hidden]="!busy" class="text-center">
    <!-- <mat-spinner [diameter]="42"></mat-spinner> -->
    <img src="assets/images/loader.gif" width="120">
    <!-- <div class="message text-center" style="width: 100%; margin-left: -14px; color: navy; font-weight:700" *ngIf="message">
      <span>{{message}}</span>
    </div> -->
    <br />
  </div>
</div>
