import { RouteInfo } from './horizontal-sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard/Home',
        title: 'Home',
        icon: 'home',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['2', '1', '3'],
        submenu: []
    },
    {
        path: '/dashboard/ad-injector',
        title: 'Dashboard',
        icon: 'Database',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['2', '1', '4', '3'],
        submenu: []
    },
    {
        path: '',
        title: 'Reports',
        icon: 'File',
        class: 'has-arrow',
        ddclass: 'one-column',
        extralink: false,
        role: ['2', '1', '3'],
        submenu: [
            {
                path: '/managereports/manage-reports',
                title: 'Total Report',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/managereports/report-count',
                title: 'Resource Wise Report',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/dashboard/CompanyReport',
                title: 'Company Reports',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: ['1'],
                submenu: []
            },
            {
                path: '/dashboard/AffiliateReport',
                title: 'Affiliate Partner Report',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: ['1'],
                submenu: []
            },
        ]
    },
    {
        path: '/dashboard/StatusReport',
        title: 'Status Reports',
        icon: 'Target',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['4'],
        submenu: []
    },
    {
        path: '/admin/manage-user',
        title: 'Manage User',
        icon: 'Users',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['2', '1'],
        submenu: []
    },
    {
        path: '/admin/manage-company',
        title: 'Company',
        icon: 'Briefcase',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['1'],
        submenu: []
    },
    {
        path: '/salesLead/addwarelist',
        title: 'Adware Hunting',
        icon: 'Globe',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['3'],
        submenu: []
    },
    {
        path: '/salesLead/manageLead',
        title: 'Sales Lead',
        icon: 'Command',
        class: '',
        ddclass: '',
        extralink: false,
        role: ['3'],
        submenu: []
    },
    {
        path: '',
        title: 'Sales Lead',
        icon: 'Command',
        class: 'has-arrow',
        ddclass: 'one-column',
        extralink: false,
        role: ['1','2'],
        submenu: [
            {
                path: '/salesLead/manageLead',
                title: 'Sales Lead',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/salesLead/lead-overview',
                title: 'Lead Overview',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/salesLead/sales-lead-report',
                title: 'Lead Report',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'Settings',
        icon: 'settings',
        class: 'has-arrow',
        ddclass: 'two-column',
        extralink: false,
        role: ['1'],
        submenu: [
            {
                path: '/admin/manage-AffModel',
                title: 'Aff Model',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-browser',
                title: 'Browser',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-creativeType',
                title: 'Creative Type',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-emailtemplate',
                title: 'Email-Template',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-installation',
                title: 'Installation',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-masterNetwork',
                title: 'Network',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-PageType',
                title: 'Page Type',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-platform',
                title: 'Platform',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-proxy',
                title: 'Proxy',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-searchengine',
                title: 'Search Engine',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-brand',
                title: 'Brand',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/package-list',
                title: 'Package',
                icon: 'mdi mdi-inbox',
                class: '',
                extralink: false,
                ddclass: '',
                role: [],
                submenu: []
            },
            {
                path: '/managereports/validation-list',
                title: 'Validation Checklist',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: ['2', '1'],
                submenu: []
            },
            {
                path: '/admin/add-team',
                title: 'Add Team',
                icon: 'mdi mdi-inbox',
                class: '',
                extralink: false,
                ddclass: '',
                role: [],
                submenu: []
            },
            {
                path: '/salesLead/addwarelist',
                title: 'Adware Hunting',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/salesLead/adwareapp-type',
                title: 'App Behavior Type',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/salesLead/app-behavior',
                title: 'Adware App Behavior',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manageTeam',
                title: 'Assign Team',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/add-domain',
                title: 'Domain Twist',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
            {
                path: '/admin/manage-adwareType',
                title: 'Adware Type',
                icon: 'mdi mdi-inbox',
                class: '',
                ddclass: '',
                extralink: false,
                role: [],
                submenu: []
            },
        ]
    },
];
