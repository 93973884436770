import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/authentication/login/login.component';
import { PageNotFoundComponent } from './core/authentication/page-not-found/page-not-found.component';
import { SignupComponent } from './core/authentication/signup/signup.component';
import { AuthGuard } from './core/guards/auth.guard';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule), canActivate:[AuthGuard]},
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate:[AuthGuard]},
      { path: 'managereports', loadChildren: () => import('./managereports/managereports.module').then(m => m.ManagereportsModule), canActivate:[AuthGuard]},
      { path: 'salesLead', loadChildren: () => import('./sales-lead/sales-lead.module').then(m => m.SalesLeadModule), canActivate:[AuthGuard]},
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          () => import('./core/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'signup',
    component:SignupComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
