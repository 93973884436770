
<section class="login2">
  <div class="bg-img2">
    <div class="centerDiv2">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <h4 class="login-heading">Sign in to our platform</h4>

          <div class="col-md-12 mt-4">
            <div class="form-group">
              <label for="email">Your Email</label>
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                <input type="text" placeholder="Enter user email" formControlName="username"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">User email is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email">Your Password</label>
              <div class="input-group">
                <span class="input-group-text"><i class="fas fa-lock"></i></span>
                <input type="password" placeholder="***********" formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control">
                <div class="form-group mb-4">

                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="form-group mb-4">
              <button type="submit" class="btn btn-block btn-secondary login-btn" [disabled]="loading">
                {{loading ? 'Loading..': 'Log In'}} <span *ngIf="loading"><img style="width: 24px;"
                    src="assets/images/mini-loader.gif" alt="" class="load-img ml-2"></span>
              </button>
            </div>
            <div class="text-center">
              <img src="assets/images/logoLogin.png" width="150px" alt="">
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</section>

