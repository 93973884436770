import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserDetails } from '../../../app/shared/models/user-details.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private currentUserSubject: BehaviorSubject<UserDetails>;
  public currentUser: Observable<UserDetails>;

  constructor() {
    this.currentUserSubject = new BehaviorSubject<UserDetails>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  setUserInfo(userInfo:any) {
    const minutesToAdd=720;
    const currentDate = new Date();
    const expireOn = new Date(currentDate.getTime() + minutesToAdd*60000);
    let model=userInfo.data;
    model.expireOn=expireOn
    localStorage.setItem('currentUser', JSON.stringify(model));
  }

  emptyLocalStorage() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(new UserDetails);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('currentUser') || '{}');
  }
}
