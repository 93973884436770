import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountServices } from '../../services/account.service';
import { ShareLinkLogService } from '../../services/share-link-log.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    companyId: [null],
    affiliatePartner: [null]
  });
  recoverform = false;
  submitted = false;
  returnUrl: string;
  loading = false;
  isLoginWithSharedLink: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _accountServices: AccountServices,
    private _shareLInkLogservices: ShareLinkLogService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

    const loginObjectString = this.route.snapshot.paramMap.get('accesskey')
    if (loginObjectString) {
      let loginObj;
      try {
        loginObj = JSON.parse(atob(loginObjectString));
      }
      catch {
        loginObj = null;
        this.toastr.error('Invalid URL', 'Error')
      }

      if (loginObj && loginObj.userName && loginObj.password) {
        this.loginForm.patchValue({
          username: loginObj.userName,
          password: loginObj.password,
          companyId: loginObj.cid,
          affiliatePartner: loginObj.afId
        })
        this.onSubmit();
        this.isLoginWithSharedLink = true;
      }
    }

    if (this._accountServices.currentUserValue.emailId) {
      this.router.navigate(['/dashboard/ad-injector']);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/ad-injector'
  }

  ngOnInit(): void {
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const data = this.loginForm.value
    var loginDetail = {
      email: data.username,
      password: data.password,
      companyId: data.companyId,
      affiliatePartner: data.affiliatePartner,
      sessionId: '',
      ip: ''
    }
    this.loading = true;
    this._accountServices.postUserLogin(loginDetail).subscribe(
      data => {
        if (data) {
          sessionStorage.setItem('roleId', data.roleId);
          sessionStorage.setItem('Id', data.id);
          sessionStorage.setItem('roleName', data.roleName);
          sessionStorage.setItem('name', data.name);
          sessionStorage.setItem('email', data.email);
          sessionStorage.setItem('companyId', data.companyId);
          sessionStorage.setItem('affiliatePartner', loginDetail.affiliatePartner);
          if (this.isLoginWithSharedLink) {
            var shareModelLog = {
              companyId: data.companyId,
              userId: data.id
            }
            this._shareLInkLogservices.postShareLinkLog(shareModelLog).subscribe(x => {
              console.log('successfull');
            });
          }
          this.redirectUser(data)
        } else {
          this.loading = false;
          this.toastr.error('Username or password is incorrect');
        }
      },
      error => {
        this.loading = false;
        console.error(error);
        this.toastr.error('Username or password is incorrect');
      },
      () => {
        setTimeout(() => {
          this.loading = false;
        }, 5000);

      }
    )

  }

  forgetPassword() {
    this.router.navigate(['/authentication/forget-password']);
  }

  signUp() {
    this.router.navigate(['/authentication/signup']);
  }

  redirectUser(data: any) {
    if (data) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/dashboard/ad-injector']));
      window.open('#' + url, '_self');
      location.reload();
    }
    else {
      this.toastr.error('Invalid user-name or password');
    }
  }

}
