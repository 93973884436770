import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlParams} from '../constants/URL_PARAMS';
import {Observable, BehaviorSubject, fromEventPattern} from 'rxjs';
import { UserDetails } from 'src/app/shared/models/user-details.model';
import { LocalStorageService } from './local-storage.service';
import { map } from 'rxjs/internal/operators/map';
import { AddUser } from 'src/app/shared/models/add-user.model';
@Injectable({
  providedIn: 'root'
})
export class AccountServices{

  private currentUserSubject: BehaviorSubject<UserDetails>;
  public currentUser: Observable<UserDetails>;

  constructor(private http: HttpClient, private storage: LocalStorageService) {
    this.currentUserSubject = new BehaviorSubject<UserDetails>(this.storage.getUserInfo());
    this.currentUser = this.currentUserSubject.asObservable();
  } 

  public get currentUserValue(): UserDetails {
    return this.currentUserSubject.value;
  }

  postCreateUser(addUser :AddUser): Observable<any>{
    const url = `${UrlParams.ACCOUNT.CREATE_USER}`;
    return this.http.post<any>(url,addUser);
  }

  postUserLogin(userDetail :any): Observable<any>{
    const url = `${UrlParams.ACCOUNT.USER_LOGIN}`;
    return this.http.post<any>(url,userDetail)
    .pipe(map(user => {
      if (user.message === 'Successfull' && user.data) {
        this.storage.setUserInfo(user);
        this.currentUserSubject.next(user.data);
      }
      return user.data;
    }));
  }

  getForgetPassword(email:string): Observable<any>{
    const url = `${UrlParams.ACCOUNT.FORGET_PASSWORD}/${email}`;
    return this.http.get<any>(url);
  }

  postResetPassword(otpDetail :any): Observable<any>{
    const url = `${UrlParams.ACCOUNT.RESET_PASSWORD}`;
    return this.http.post<any>(url,otpDetail);
  }

  postChangePassword(email :any): Observable<any>{
    const url = `${UrlParams.ACCOUNT.UPDATE_PASSWORD}`;
    return this.http.post<any>(url,email);
  }

  logout() {
    this.storage.emptyLocalStorage();
    var obj = new UserDetails();
    this.currentUserSubject.next(obj);
  }

}
