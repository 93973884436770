import { Component } from '@angular/core';
import { UrlParams } from './core/constants/URL_PARAMS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vpt-adInjector';
  constructor() {
    const lastSlash = window.location.href.split('/', 3).join('/').length;
    const host = window.location.href.substr(0, lastSlash + 1);
    UrlParams.init(host);
  }
}
