import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './shared.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoadingComponent } from 'src/app/shared/loading/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    declarations: [LoadingComponent,],
    imports: [
        CommonModule,
        NgxPaginationModule,
    ],
    exports: [
        NgxPaginationModule,
        LoadingComponent,
        MatProgressSpinnerModule
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [SharedService]
    };
}
 }
