import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { SetDefault } from 'src/app/shared/models/add-setDefault';
import { UrlParams } from '../constants/URL_PARAMS';

@Injectable({
  providedIn: 'root'
})
export class LeadGenerationService {

  constructor(private http: HttpClient) {}

  addCreatelead(model:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.CREATE_ADDLEAD}`;
    return this.http.post<any>(url, model);
  }
  createTask(model:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.CREATE_TASK}`;
    return this.http.post<any>(url, model);
  }
  getLeadList(): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_LEADLIST}`;
    return this.http.get<any>(url)
  }
  getLeadListEdit(Id: any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_LEADLISTEDIT}/${Id}`;
    return this.http.get<any>(url)
  }
  getAssignedLeadList(resourceId: any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_ASSIGNEDLEADLIST}/${resourceId}`;
    return this.http.get<any>(url)
  }
  updateLead(formData:FormData, id: any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_EDITLEAD}/${id}`;
    return this.http.post<any>(url, formData)
  }
  leadAssignment(model:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.CREATE_ASSIGNLEAD}`;
    return this.http.post<any>(url, model);
  }
  postEmail(emailModel :any): Observable<any>{
    const url = `${UrlParams.LEAD_GENERATION.CREATE_SENDEMAIL}`;
    return this.http.post<any>(url,emailModel);
  }
  getSalesLeadReport(): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_SALES_LEAD_REPORT}`;
    return this.http.get<any>(url)
  }
  getSalesLeadDetailedReport(ObservationCode:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_DETAILED_LEAD_REPORT}/${ObservationCode}`;
    return this.http.get<any>(url)
  }
  postCreateDeal(model :any): Observable<any>{
    const url = `${UrlParams.LEAD_GENERATION.CREATE_DEAL}`;
    return this.http.post<any>(url,model);
  }
  getDealOverviewList(id:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_DEAL_OVERVIEW_LIST}/${id}`;
    return this.http.get<any>(url)
  }
  getDealMasterList(): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.GET_DEAL_MASTER_LIST}`;
    return this.http.get<any>(url)
  }
  updateNotes(model: any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.UPDATE_NOTES}`;
    return this.http.post<any>(url, model)
  }
  recentCommunicationList(): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.RECENT_COMMUNICATION_LIST}`;
    return this.http.get<any>(url)
  }
  activityLogList(LeadId:any): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.ACTIVITY_LOG_LIST}/${LeadId}`;
    return this.http.get<any>(url)
  }
  updateReadStatus(): Observable<any> {
    const url = `${UrlParams.LEAD_GENERATION.UPDATE_READ_STATUS}`;
    return this.http.post<any>(url,null)
  }
}
